import posthog from "posthog-js"
import { createContext, useContext, ReactNode, useState, useEffect } from "react"

const PosthogLoadedContext = createContext(false)

const PUBLIC_API_KEY = "phc_np9jkMajx38ECncUkQi48XEVEpjzhpDCurLJlrYC9NN"

export function usePosthogLoaded() {
	return useContext(PosthogLoadedContext)
}

export function Posthog({ children }: { children: ReactNode }) {
	const [posthogLoaded, setPosthogLoaded] = useState(false)

	useEffect(() => {
			posthog.init(PUBLIC_API_KEY, {
				api_host: `https://ingest.infinitysort.com`,
				ui_host: 'https://us.posthog.com',
				loaded: () => setPosthogLoaded(true)
			})
	}, [])

	return (
		<PosthogLoadedContext.Provider value={posthogLoaded}>
			{children}
		</PosthogLoadedContext.Provider>
	)
}